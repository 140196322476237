<template>
  <div class="model-container">
    <!-- <div class="model-wrap">
      <div class="model-box">
        <div class="model-head-box">
          <div class="model-head">温馨提示</div>
          <button
            @click="hideModel()"
            class="model-headBtn">
            <i class="el-message-box__close el-icon-close"></i>
          </button>
        </div>
        <div class="model-content">
          <p>{{NotMemberStr}}</p>
        </div>
        <div class="model-btn-box">
          <p class="Open" @click="hideModel()">我知道了</p>
        </div>
      </div>
    </div> -->
    <div class="error-model">
      <div class="error-warp">
        <div class="error-box">
          <div class="errror-head-box">
            <div class="error-head">移除联系人原因</div>
            <i @click="hideError()" class="el-message-box__close el-icon-close"></i>
          </div>
          <div class="error-content">
            <div class="choice">
              <el-radio v-model="radio" label="0">联系人已离职</el-radio>
              <el-radio v-model="radio" label="1">联系电话错误</el-radio>
            </div>
            <div
              @click="submit(currentContact)"
              class="submit">提交</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      radio: '0'
    }
  },
  props: [
    'currentContact'
  ],
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  methods: {
    hideModel () {
      this.$emit('NotMembershow', false)
    },
    hideError () {
      this.$emit('Errorshow', false)
    },
    understand () {
      window.open('http://u293387.viewer.maka.im/pcviewer/YZ4CSFE6')
    },
    goReward () {
      window.open('http://bizsearch.winshangdata.com/correction.aspx?pclxr')
    },
    submit (currentContact) {
      this.axios.post(api.removeContact, { brand_id: this.$route.query.id, isLinkManNew: currentContact.isLinkManNew, lmId: currentContact.lmId, userid: this.user.userid, gbid: this.$route.query.gbid, ppyid: currentContact.ppyid, delWhy: this.radio })
        .then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '已经移除该联系人'
            })
            this.$emit('Errorshow', true)
          } else {
            this.$message({
              type: 'waring',
              message: '移除该联系人错误,请重新移除!'
            })
          }
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
.model-wrap
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  text-align center
  background-color rgba(0, 0, 0, 0.5)
  .model-box
    margin-top 200px
    text-align center
    display inline-block
    vertical-align middle
    background-color #fff
    width 420px
    border-radius 3px
    font-size 16px
    overflow hidden
    -webkit-backface-visibility hidden
    backface-visibility hidden
    .model-head-box
      position relative
      padding 20px 20px 0
      .model-headBtn
        background-color #ffffff
        position absolute
        top 20px
        right 20px
        cursor pointer
        i
          color #ccc
        i:hover
          color #20a0ff
  .model-content
    padding 15px
    background-color #fff
    text-align left
    line-height 26px
    .content-center
      padding 10px 0
      color #999
  .model-btn-box
    text-align center
    padding 10px 0
    .Open
      cursor pointer
      background #3585ff
      border-radius 5px
      color #FFF
      width 80%
      height 40px
      line-height 40px
      display inline-block
      margin 0 10%
    .understand
      margin-top 20px
      cursor pointer
      color #3585ff
.error-model
  position fixed
  top 0px
  left 0px
  right 0px
  bottom 0px
  text-align left
  background-color rgba(0, 0, 0, 0.5)
  .error-warp
    width 420px
    margin 260px auto
    .error-box
      background-color #fff
      border-radius 3px
      .errror-head-box
        text-align center
        padding 20px 20px 20px
        border-bottom 1px solid #ccc
        position relative
        .error-head
          font-weight bold
        i
          color #ccc
          position absolute
          right 20px
          top 20px
          cursor pointer
        i:hover
          color #ffa134
      .error-content
        height 100px
        padding 15px 20px 40px 20px
        .prompt
          font-size 12px
          color #666
        .contacts-details
          padding 30px
          background-color #f9f9f9
          margin 10px 0
          .contactsPosition
            margin 3px 0 13px 0
          >p
            font-size 14px
            color #323232
            margin 4px 0
        .choice
        p
          margin 30px 0
          text-align center
        .submit
          width 120px
          height 35px
          text-align center
          margin 20px auto
          line-height 35px
          color #fff
          background-color #ffa134
          border-radius 3px
          cursor pointer
        .succ
          color #4d4d4d
          font-size 14px
          margin-top 15px
          text-align center
          span
            color #488efe
            text-decoration underline
            cursor pointer
</style>
