<template>
  <div class="drop-brand">
    <div class="add-drop">
      <i
        class="el-icon-close"
        @click="hideAddDrop"
      ></i>
      <h3>从我关注的品牌添加</h3>
      <div class="small-brand-label">
        <div class="small-label-list">
          <span class="small-label-title fl"><i class="small-line"></i>品牌分类</span>
          <div class="small-label-title-div fl">
            <span
              :class="[list2Idx === index ? 'active': '']"
              v-for="(item, index) in list2"
              :key="index"
              @click="typeClick(item, index)"
            >{{item.label}}</span>
          </div>
        </div>
        <div class="small-label-list">
          <span class="small-label-title">
            <i class="small-line"></i>品牌排序
            <i
              class="icon-mark"
              @click="tipsHide=true"
            ></i>
          </span>
          <span
            :class="[list1Idx === index ? 'active': '']"
            v-for="(item, index) in list1"
            :key="index"
            @click="pxClick(item, index)"
          >{{item.name}}</span>
        </div>
      </div>
      <div class="content-wrap">
        <div class="content-right">
          <div
            class="brand-list"
            v-loading="recommendLoad"
            element-loading-text="加载中..."
          >
            <ul>
              <li
                v-for="(item, index) in brandList.dataList"
                :key="index"
                :class="[clickIndex === index ? 'active': '']"
              >
                <div @click.stop="toDetails(item)">
                  <div class="brand-logo">
                    <!-- <span
                      v-if="item.trend === 1"
                      class="star-tag"
                    ></span> -->
                    <img
                      :src="item.brandLogo?item.brandLogo:'https://jscss.winshangdata.com/3.0/list/images/no-logo.png'"
                      alt=""
                    >
                    <span>{{item.brandName}}</span>
                  </div>
                  <p class="brand-type">{{item.industry}}{{item.areaMin? '/' + item.areaMin + ' - ': ''}}{{item.areaMax?item.areaMax + '平米': ''}}</p>
                  <p class="brand-type">已进驻{{item.shopNum}}家购物中心</p>
                </div>
                <div
                  class="item-btns"
                  @click="selectRecomBrand(item, index)"
                  @mousemove="changeActive($event, index)"
                  @mouseout="remoActive($event)"
                >添加落位品牌</div>
                <span
                  v-show="moIndex === index"
                  class="tips"
                >{{clickIndex === index ? '点击取消该品牌': '点击选择该品牌'}}</span>
                <i class="jibiao"></i>
              </li>
            </ul>
            <div
              class="no-recommend-brand"
              v-show="brandList.dataList && brandList.dataList.length === 0"
            >
              <img
                src="@/assets/images/workBench/recomdNot.png"
                alt=""
              >
              <p>暂无关注的品牌</p>
            </div>
          </div>
        </div>
      </div>
      <div class="follow-list-wrap w-page-sty">
        <el-pagination
          background
          v-if="brandList.dataList && brandList.total > 15"
          layout="prev, pager, next"
          @current-change="pageChange"
          :page-size="16"
          :total="brandList.total"
        >
        </el-pagination>
      </div>
      <!-- <div class="samll-brand-footer btn-wrap">
        <span class="cancal w200" @click="hideAddDrop">取消</span>
        <span class="w200" @click="selectMyFollowBrand()">保存</span>
      </div> -->
    </div>
    <brand-index
      v-if="tipsHide"
      @closeBrandIndex="closeBrandIndex"
    ></brand-index>
  </div>
</template>

<script>
import api from '@/api'
import brandIndex from '@/components/brandIndexTips.vue'
export default {
  data () {
    return {
      brandList: {},
      recommendLoad: false,
      brandFalse: false,
      berthNo: '',
      brandId: 21063,
      gbid: 63534,
      clickIndex: '',
      moIndex: '',
      tipsHide: false,
      list1: [
        { name: '默认排序', value: '' },
        { name: '按人气指数排序', value: 'hot' },
        { name: '按潜力指数排序', value: 'potential' },
        { name: '按租金贡献指数排序', value: 'operating' },
        { name: '按综合实力指数排序', value: 'comprehensive' }
      ],
      list1Idx: 0,
      list2: [],
      list2Idx: 0,
      typeStr: '',
      indexOrder: '',
      selectObj: {},
      pageNum: 1
    }
  },
  components: {
    brandIndex
  },
  created () {
    this.getMyFollowFunc()
    this.getListIndustry()
  },
  methods: {
    closeBrandIndex () {
      this.tipsHide = false
    },
    toDetails (item) {
      const brandDetailsHref = this.$router.resolve({
        path: '/brandDetail',
        query: {
          id: item.brandId,
          source: 1,
          gbid: item.gbid,
          questionState: 0
        }
      })
      window.open('/' + brandDetailsHref.href, '_blank')
    },
    getListIndustry () {
      this.axios.post(api.getListIndustry1).then((res) => {
        if (res.data.code === 0) {
          res.data.data.unshift(
            {
              name: '全部',
              id: '全部',
              value: '全部'
            }
          )
        }
        this.list2 = res.data.data.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        })
      })
    },
    getMyFollowFunc () {
      this.axios.post(api.myFollowList, { indexOrder: this.indexOrder, industry1: this.typeStr, pageNum: this.pageNum, pageSize: 16 })
        .then((res) => {
          this.brandList = res.data.data
        })
    },
    pageChange (val) {
      this.pageNum = val
      this.getMyFollowFunc()
    },
    typeClick (item, index) {
      this.pageNum = 1
      this.list2Idx = index
      if (item.label !== '全部') {
        this.typeStr = item.label
      } else {
        this.typeStr = ''
      }
      this.getMyFollowFunc()
    },
    pxClick (item, index) {
      this.pageNum = 1
      this.list1Idx = index
      this.indexOrder = item.value
      this.getMyFollowFunc()
    },
    selectRecomBrand (item, index) {
      if (index === this.clickIndex) {
        this.clickIndex = -1
        this.selectObj = {}
        this.brandKeyWord = ''
        return false
      } else {
        this.selectObj = item
        this.brandKeyWord = item.brandName
        this.clickIndex = index
        this.$emit('selectMyFollowEmit', this.selectObj)
      }
    },
    hideAddDrop () {
      this.$emit('hideMyFollowEmit')
    },
    changeActive ($event, index) {
      this.moIndex = index
    },
    selectMyFollowBrand () {
      if (JSON.stringify(this.selectObj) !== '{}') {
        this.$emit('selectMyFollowEmit', this.selectObj)
      } else {
      }
    },
    remoActive ($event) {
      this.moIndex = ''
    }
  }
}
</script>

<style lang="stylus" scoped>
.drop-brand
  position fixed
  left 0
  top 0
  bottom 0
  right 0
  background rgba(0, 0, 0, 0.5)
  z-index 11
  .add-drop
    position absolute
    left 50%
    margin-left -454px
    top 80px
    width 868px
    min-height 430px
    background #ffffff
    border-radius 6px
    padding 40px 20px
    .el-icon-close
      position absolute
      right 20px
      top 15px
      color #D9D9D9
      cursor pointer
    h3
      font-size 18px
      color #333
      font-weight bold
.content-wrap
  .content-right
    position relative
    border 1px solid rgba(238, 238, 238, 1)
    box-shadow 0px 5px 8px 0px rgba(50, 29, 6, 0.04)
    .brand-list
      height 295px
      overflow auto
      ul
        overflow hidden
        padding-top 22px
        padding-left 18px
        li
          position relative
          float left
          width 198px
          border 1px solid #ffffff
          height 172px
          box-shadow 0px 9px 32px 0px rgba(50, 29, 6, 0.06)
          cursor pointer
          margin-right 10px
          margin-bottom 10px
          &.active
            border 1px solid #FFA134
            .jibiao
              position absolute
              right 0
              bottom 0
              display inline-block
              width 19px
              height 18px
              background url('~@/assets/images/workBench/jiaobiao.png') no-repeat
          &:nth-child(4n)
            margin-right 0
          .brand-logo
            display flex
            align-items center
            margin-bottom 12px
            img
              width 77px
              height 57px
              margin-top 10px
              margin-left 10px
              object-fit cover
            span
              margin-left 10px
              font-size 14px
              color #333
              font-weight 500
          .brand-type
            font-size 12px
            color #666
            font-weight 500
            margin-left 10px
            margin-top 8px
            margin-bottom 5px
          .rate-wrap
            display flex
            font-size 12px
            color #666
            font-weight 500
            margin-left 10px
          .tips
            position absolute
            left 50%
            margin-left -65px
            top 45%
            width 130px
            height 27px
            line-height 27px
            text-align center
            color #ffffff
            font-size 12px
            background rgba(0, 0, 0, 0.5)
            transition all 0.05s
          .item-btns
            position absolute
            bottom 10px
            left 10px
            width 174px
            height 32px
            line-height 32px
            background rgba(241, 241, 241, 1)
            border-radius 4px
            text-align center
            color #666
            font-size 14px
            cursor pointer
            &:hover
              background rgba(246, 246, 246, 1)
              color #333
.btn-wrap
  text-align center
  margin-top 30px
  span
    display inline-block
    width 80px
    height 40px
    line-height 40px
    text-align center
    color #fff
    background #FFA134
    font-size 16px
    font-weight 400
    border-radius 4px
    cursor pointer
    margin 0 10px
    &.cancal
      background #ffffff
      color #FFA134
      border 1px solid #FFA134
    &.w200
      width 160px
.brand-list
  &::-webkit-scrollbar
    width 6px
  &::-webkit-scrollbar-track
    // background-color: #f2f2f2;
    border-radius 10px
  /* 滚动条的滑轨背景颜色 */
  &::-webkit-scrollbar-thumb
    background-color #D9D9D9
    border-radius 10px
  /* 滑块颜色 */
  &::-webkit-scrollbar-button
    // background-color: #000;
  /* 滑轨两头的监听按钮颜色 */
  &::-webkit-scrollbar-corner
    // background-color: black;
  /* 横向滚动条和纵向滚动条相交处尖角的颜色 */
.smart-brand-tip
  line-height 23px
  color #FFBD70
  font-size 13px
  margin-top 28px
  display flex
  padding 0 43px 0 6px
  i
    margin-right 10px
.small-label-list
  margin 20px 0 25px 4px
  span
    color #999
    font-size 14px
    margin-right 38px
    line-height 24px
    cursor pointer
    &.active
      color #FFA134
  .small-label-title
    display inline-block
    color #333
    font-size 14px
    cursor default
    width 90px
    margin-right 20px
    i.small-line
      display inline-block
      width 2px
      height 14px
      background-color #FFA134
      vertical-align -2px
      margin-right 5px
    .icon-mark
      cursor pointer
      &:before
        font-size 16px
        margin-left 3px
.brandIndex-tips
  position fixed
  top 0
  left 0
  bottom 0
  right 0
  width 100%
  height 100%
  background rgba(0, 0, 0, 0.5)
  z-index 111
  .tips-box
    position fixed
    top 20%
    left 50%
    margin-left -325px
    width 650px
    padding 20px 32px 40px 32px
    background #fff
    h3
      text-align center
      color #333
      font-size 28px
    .content
      margin-top 25px
      .title
        color #333
        font-size 14px
        margin-top 6px
        margin-bottom 6px
      .text
        color #666
        font-size 14px
        line-height 22px
    .el-icon-close
      cursor pointer
.no-recommend-brand
  text-align center
  color #333
  font-size 14px
  margin-top 65px
  p
    margin-top 20px
.star-tag
  left 0px
  top 0px
.follow-list-wrap
  text-align center
  margin-top 20px
.small-label-title-div
  width 80%
  margin-left 4px
</style>
