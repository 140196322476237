<template>
  <div class="drop-brand is-scroll-x">
    <div class="add-drop">
      <div class="fixed-wrap">
        <i
          class="el-icon-close"
          @click="hideAddDropClose"
        ></i>
        <h3>计划落位品牌管理</h3>
      </div>
      <div
        class="drop-content"
        ref="dropContent"
      >
        <div
          v-if="dropEdit !== 6"
          class="projectName"
        >{{projectName}}</div>
        <div
          v-if="dropEdit === 6"
          class="projectName projectName2 addrop-input"
        >
          <el-select
            :style="{width: inputNumber + 'px'}"
            popper-class="floorSelectPoper"
            @change="changeProject"
            v-model="selectprojectName"
          >
            <el-option
              v-for="item in projectList"
              :key="item.value"
              :label="item.label"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="content-wrap">
          <div class="title"><span></span> 落位铺位</div>
          <div class="search-wrap fl">
            <p><i class="mustFill">*</i>落位方案：</p>
            <el-select
              v-model="planNameStr"
              @change="changePlan"
              popper-class="floorSelectPoper"
              placeholder="请选择"
            >
              <el-option
                v-for="item in planList"
                :key="item.value"
                :label="item.label"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div class="search-wrap fr">
            <p><i class="mustFill">*</i>落位铺位：</p>
            <el-cascader
              popper-class="floorCascaderPoper"
              v-model="floorStr"
              placeholder="请选择落位铺位"
              :options="floorArr"
              filterable
              @change="getFloorNumber"
            ></el-cascader>
          </div>
        </div>
        <div class="drop-brand-wrap">
          <div class="title"><span></span> 落位品牌</div>
        </div>
        <!-- <div class="no-data-tips" v-show="planData.planBrands && planData.planBrands.length === 0">
          <span class="el-icon-information"></span>
          暂无落位品牌，编辑下面内容添加</div> -->
        <div
          class="content-left"
          v-if="isAddBrand"
        >
          <div class="add-drop-info">
            <div class="left-item">
              <!-- <p><i class="mustFill">*</i>落位品牌</p> -->
              <div class="search-wrap">
                <i class="mustFill">*</i>落位品牌
                <input
                  type="text"
                  v-model="brandKeyWord"
                  class="searchBrand"
                  @input="searchInput"
                  @focus="hideBusinessType"
                  @blur="hideKeyworld"
                  placeholder="请输入品牌关键字并选择"
                >
                <!-- <span class="selectBtn" @click="toMyAi('add')"><i class="icon-intelligence"></i> {{btnText}}</span> -->
                <!-- <span
                  class="selectBtn"
                  @click="toMyFollow"
                >从我的关注添加</span> -->
                <div
                  class="search-list"
                  v-show="brandKeyWordListShow"
                >
                  <ul>
                    <!-- <li v-show="brandLoad">加载中...</li> -->
                    <li
                      v-show="brandKeyWordList.length > 0"
                      v-for="(item, index) in brandKeyWordList"
                      :key="index"
                      @click="addDropBrand(item)"
                    >{{item.brandName}}</li>
                    <li v-show="brandKeyWordList.length === 0 && !brandLoad">无相关品牌数据！</li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              class="left-item"
              style="width: 90.7%;"
            >
              设置首选落位
              <el-switch
                class="dropBtn"
                v-model="isDrop"
                on-color="#0FC967"
                off-color="#D9D9D9"
                :disabled="isDisadled"
                on-text=""
                off-text=""
              >
              </el-switch>
            </div>
          </div>
          <div class="drop-btn-right">
            <i
              @click="hideAddDrop"
              class="el-icon-close"
            ></i>
            <p
              class="determine"
              @click="addDropBrandNext('add')"
            >确定</p>
            <p
              class="cancal"
              @click="hideAddDrop"
            >取消</p>
          </div>
        </div>
        <div
          class="plan-brand-wrap"
          v-if="!isAddBrand && planData && planData.planBrands && planData.planBrands.length > 0"
        >
          <div
            v-for="(item, index) in planData.planBrands"
            :key="index"
          >
            <div
              class="plan-brand-item"
              v-show="editIndex === null"
            >
              <i class="sort-num">{{index+1}}</i>
              <span
                class="icon-del"
                @click="deleteDropBrnad(item.task.taskState, item.id, item.brand.brandName)"
                v-if="item.approvalStatus !== '审批中'"
              ></span>
              <span
                class="icon-edit2"
                v-if="item.approvalStatus !== '审批中'"
                @click="editDropBrand(item, index)"
              ></span>
              <img
                :src="item.brand.brandLogo?item.brand.brandLogo:'https://jscss.winshangdata.com/3.0/list/images/no-logo.png'"
                alt=""
                @click="toInvesDetails(item)"
              >
              <div
                class="info"
                @click="toInvesDetails(item)"
              >
                <div
                  class="brandName"
                  @click.stop="toDetails(item)"
                >{{item.brand.brandName}}</div>
                <div class="formatName"> <i>{{item.brand.industry}} </i> <span> 招商状态：{{item.task.taskState}}</span> <span>首选落位：{{item.isFirst === 1 ? '是': '否'}}</span></div>
              </div>
            </div>
            <!-- 编辑框 -->
            <div
              class="content-left"
              v-if="editIndex === index"
            >
              <div class="add-drop-info">
                <div class="left-item">
                  <!-- <p></p> -->
                  <div class="search-wrap">
                    <i class="mustFill">*</i>落位品牌
                    <input
                      type="text"
                      v-model="selectObj.brandName"
                      class="searchBrand"
                      @input="searchInputEdit"
                      @focus="hideBusinessType2"
                      @blur="hideKeyworld2"
                      placeholder="要更换的品牌名称"
                    >
                    <span
                      class="selectBtn"
                      @click="toMyFollow"
                    >从我的关注添加</span>
                    <div
                      class="search-list"
                      v-show="brandKeyWordListShow2"
                    >
                      <ul>
                        <li
                          v-show="brandKeyWordList2.length > 0"
                          v-for="(item, index) in brandKeyWordList2"
                          :key="index"
                          @click="addDropBrand2(item)"
                        >{{item.brandName}}</li>
                        <li v-show="brandKeyWordList2.length === 0">无相关品牌数据！</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  class="left-item"
                  style="width: 90.7%;"
                >
                  设置首选落位
                  <el-switch
                    class="dropBtn"
                    v-model="isDropStatus"
                    on-color="#0FC967"
                    off-color="#D9D9D9"
                    :disabled="isDisadled2"
                    on-text=""
                    off-text=""
                  >
                  </el-switch>
                </div>
              </div>
              <div class="drop-btn-right">
                <i
                  @click="hideAddDropEdit"
                  class="el-icon-close"
                ></i>
                <p
                  class="determine"
                  @click="addDropBrandNext('edit')"
                >确定</p>
                <p
                  class="cancal"
                  @click.stop="hideAddDropEdit"
                >取消</p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="no-recommend-brand"
          v-show="planData && planData.planBrands && planData.planBrands.length === 0 && addPlanBtn"
        >
          <img
            src="@/assets/images/workBench/recomdNot.png"
            alt=""
          >
          <p>暂无计划品牌</p>
        </div>
      </div>
      <div
        class="add-drop-btn"
        v-if="addPlanBtn"
        :class="[(planData.planBrands && planData.planBrands.length === 5000) ? 'cannot': '']"
        @click="addDropBtn"
      >添加计划品牌</div>
    </div>
    <from-follow
      v-if="isMyFollowShow"
      @selectMyFollowEmit="selectMyFollowEmit"
      @hideMyFollowEmit="hideMyFollowEmit"
    ></from-follow>
  </div>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
import fromFollow from '@/views/workBench/components/addBrandFromFocus.vue'
export default {
  data () {
    return {
      brandLoad: true,
      brandKeyWord: '',
      brandKeyWordList: [],
      brandKeyWordListShow: false,
      isDrop: true,
      rateVal: 5,
      moIndex: '',
      isBrandLibrary: false,
      clickIndex: '',
      selectObj: {},
      floorStr: [],
      building: '',
      floorVal: '',
      shopArr: [],
      checked: false,
      dropText: '添加',
      index: 1,
      brandList: [],
      recommendLoad: true,
      brandFalse: false,
      berthNo: '',
      brandId: 21063,
      gbid: 63534,
      planList: [],
      isAddBrand: false,
      planData: {},
      projectList: [],
      selectprojectName: '',
      inputNumber: 200,
      floorArr: [],
      isMyFollowShow: false,
      planNameStr: '',
      isDisadled: false,
      isDisadled2: false,
      isMyAiShow: false,
      editIndex: null,
      matchType: 'add',
      brandKeyWordList2: [],
      brandKeyWordListShow2: false,
      brandDataProp: {},
      isDropStatus: true,
      addPlanBtn: false,
      btnText: '智能品牌匹配',
      flag: false,
      currentKeywordBrand: {},
      selectprojectId: 0,
      planId: ''
    }
  },
  props: {
    brandDetails: {
      type: Object
    },
    recommendObj: {
      type: Object
    },
    projectId: {
      type: Number
    },
    taskId: {
      type: Number
    },
    dropEdit: {
      type: Number
    },
    planName: {
      type: String
    },
    projectName: {
      type: String
    },
    delset: {
      type: Boolean,
      default: true
    }
  },
  components: {
    fromFollow
  },
  computed: {
    ...mapGetters({
      user: 'user',
      token: 'token'
    })
  },
  beforeDestroy () {
    window.removeEventListener('click', this.closeClick)
  },
  mounted () {
    window.addEventListener('click', this.closeClick)
  },
  created () {
    // console.log(this.dropEdit)
    // console.log(this.planName)
    // console.log(this.brandDetails)
    this.planId = this.taskId
    // 4.2需求变更逻辑
    if (this.dropEdit === 0) {
      this.isAddBrand = true
    }
    if (JSON.stringify(this.brandDetails) !== '{}' && this.dropEdit !== 6) {
      if (this.brandDetails.berth) {
        this.floorStr.push(this.brandDetails.berth.building)
        this.floorStr.push(this.brandDetails.berth.floor)
        this.floorStr.push(this.brandDetails.berth.berthId)
        this.storeVal = this.brandDetails.berth.berthId
        this.getShopPlanList(this.brandDetails.berth.berthId)
      } else {
        this.floorStr.push(this.brandDetails.building)
        this.floorStr.push(this.brandDetails.floor)
        this.floorStr.push(this.brandDetails.berthId)
        this.storeVal = this.brandDetails.berthId
        // this.getShopPlanList(this.brandDetails.berthId)
      }
    } else if (this.dropEdit === 2) {
      this.floorStr.push(this.brandDetails.building)
      this.floorStr.push(this.brandDetails.floor)
      this.floorStr.push(this.brandDetails.berthId)
      this.storeVal = this.brandDetails.berthId
      // 默认选中首选方案
      this.getBerthProjectFunc()
      this.isAddBrand = true
    } else if (this.dropEdit === 6) {
      this.getBerthProjectFunc()
      this.isAddBrand = true
      this.selectObj = this.brandDetails
      this.brandKeyWord = this.brandDetails.brandName
    }
    // 2.0需求变更逻辑
    if (this.planIsFirst === 1) {
    } else {
      this.isDrop = false
    }
    if (this.projectId) {
      this.getlistFloor(this.projectId)
      // this.getTsakFunc(this.projectId)
    }
  },
  methods: {
    closeClick (e) {
      if (e.target.className === 'drop-brand') {
        this.hideAddDropClose()
      }
    },
    toDetails (item) {
      const userAuthorities = this.user.confIds.filter(item => {
        return item.id === 164
      })
      if (userAuthorities.length > 0) {
        window.open(this.$gaodeDomain + '/omsviews/rent/assets/enterprise?menuCode=11&tokenval=' + this.token + '&brandId=' + item.brandId + '&gbid=' + item.gbid + '&brandName=' + item.brandName, '_blank')
      } else {
        this.$message({
          type: 'warning',
          message: '抱歉，您暂无查看企业品牌详情权限！'
        })
      }
    },
    toInvesDetails (item) {
      // if (item.task.taskState === '待招商') {
      const userAuthorities = this.user.confIds.filter(item => {
        return item.id === 164
      })
      if (userAuthorities.length > 0) {
        window.open(this.$gaodeDomain + '/omsviews/rent/assets/enterprise?menuCode=11&tokenval=' + this.token + '&brandId=' + item.brandId + '&gbid=' + item.gbid + '&brandName=' + item.brandName, '_blank')
      } else {
        this.$message({
          type: 'warning',
          message: '抱歉，您暂无查看企业品牌详情权限！'
        })
      }
      // } else {
      //   const singleBrandHref = this.$router.resolve({
      //     path: '/investmentDetails',
      //     query: {
      //       id: item.task.id
      //     }
      //   })
      //   window.open('/' + singleBrandHref.href, '_blank')
      // }
    },
    getBerthProjectFunc () {
      this.axios.post(api.getBerthProjectList)
        .then((res) => {
          this.projectList = res.data.data.map((item) => {
            let obj = {}
            obj = item
            obj.label = item.projectName
            obj.value = item.id
            return obj
          })
          this.selectprojectName = this.projectList[0].projectName
          // this.projectName = this.projectList[0].projectName
          if (this.dropEdit === 0) {
            this.projectId = this.projectList[0].id
            this.getlistFloor(this.projectId)
            // this.getTsakFunc(this.projectId)
          }
          if (this.dropEdit === 6) {
            this.selectprojectId = this.projectList[0].id
            this.getlistFloor(this.selectprojectId)
            // this.getTsakFunc(this.selectprojectId)
          }
        })
    },
    getlistFloor (id) {
      this.axios.post(api.getFloorBuilding, { projectId: id })
        .then((res) => {
          this.floorArr = res.data.data
        })
    },
    changeProject (item) {
      if (typeof (item) !== 'string') {
        this.getlistFloor(item.value)
        // this.getTsakFunc(item.value)
        this.projectId = item.value
      }
    },
    getTsakFunc (id) {
      this.axios.post(api.getPlanList, { projectId: id })
        .then((res) => {
          this.planList = res.data.data.map((item) => {
            const obj = {
              label: item.planName,
              value: item.id
            }
            return obj
          })
          if (this.dropEdit === 0) {
            this.planId = this.taskId
            this.planNameStr = this.planName
          } else if (this.dropEdit === 5) {
            this.planId = this.taskId
            this.planNameStr = this.planName
          } else {
            this.planId = this.planList[0].value
            this.planNameStr = this.planList[0].label
          }
          this.getShopPlanList()
        })
    },
    changePlan (item) {
      if (typeof (item) !== 'string') {
        this.planId = item.value
        this.getShopPlanList()
      }
    },
    searchInput () {
      this.fetchBrandByKeyworld()
    },
    searchInputEdit () {
      if (this.selectObj.brandName) {
        this.brandKeyWordListShow2 = true
        this.axios.post(api.searchBrandList, { brandName: this.selectObj.brandName })
          .then((res) => {
            this.brandLoad = false
            if (res.data.code === 0) {
              this.currentKeywordBrand = ''
              this.brandKeyWordList2 = res.data.data.map((item) => {
                if (item.brandName === this.selectObj.brandName) {
                  this.currentKeywordBrand = item
                }
                // 处理默认选中状态为true
                item.active = true
                return item
              })
            }
          })
      } else {
        this.brandKeyWordListShow2 = false
      }
    },
    getFloorNumber (value) {
      this.building = value[0]
      this.floorVal = value[1]
      this.storeVal = value[2]
      if (value[1]) {
        // 店铺计划品牌列表
        this.isAddBrand = false
        this.editIndex = null
        this.getShopPlanList()
      }
    },
    getShopPlanList () {
      this.axios.post(api.getMapBrandDetails, { berthId: this.storeVal, planId: this.planId })
        .then((res) => {
          this.planData = res.data.data
          if (res.data.data) {
            if (this.planData.planBrands && this.planData.planBrands.length === 0) {
              this.isDrop = true
              this.isDisadled = true
              this.addPlanBtn = false
              this.isAddBrand = true
              this.btnText = '智能品牌匹配'
            } else {
              this.isDrop = false
              this.isDisadled = false
              this.addPlanBtn = true
              this.isAddBrand = false
            }
            if (this.dropEdit === 6) {
              if (this.flag) {
                this.addPlanBtn = true
                this.isAddBrand = false
              } else {
                this.addPlanBtn = false
                this.isAddBrand = true
              }
            }
            if (JSON.stringify(this.recommendObj) && JSON.stringify(this.recommendObj) !== '{}') {
              if (!this.flag) {
                this.isAddBrand = true
                this.addPlanBtn = false
                this.selectObj = this.recommendObj
                this.brandKeyWord = this.recommendObj.brandName
              }
            }
          }
        })
    },
    hideKeyworld () {
      if (this.selectObj.gbid === '') {
        this.selectObj.brandName = ''
        this.brandKeyWordListShow2 = false
      }
    },
    hideKeyworld2 () {
      if (this.selectObj.gbid === '') {
        this.brandKeyWord = ''
        this.brandKeyWordListShow = false
      }
    },
    hideBusinessType () {
      if (this.brandKeyWord === '') {
        this.brandKeyWord = ''
      }
    },
    hideBusinessType2 () {
      if (this.selectObj.brandName === '') {
        this.selectObj.brandName = ''
      }
    },
    fetchBrandByKeyworld () {
      let brandKeyWord = ''
      this.brandLoad = true
      if (this.brandKeyWord !== '' && this.brandKeyWord !== '请输入你想添加的品牌名称') {
        brandKeyWord = this.brandKeyWord
      }
      if (brandKeyWord) {
        this.brandKeyWordList = []
        this.brandKeyWordListShow = true
        this.axios.post(api.searchBrandList, { brandName: this.brandKeyWord })
          .then((res) => {
            this.brandLoad = false
            if (res.data.code === 0) {
              this.currentKeywordBrand = ''
              this.brandKeyWordList = res.data.data.map((item) => {
                if (item.brandName === this.brandKeyWord) {
                  this.currentKeywordBrand = item
                }
                // 处理默认选中状态为true
                item.active = true
                return item
              })
            }
          })
      } else {
        this.brandKeyWordListShow = false
      }
    },
    addDropBrand (item) {
      this.selectObj = item
      this.brandKeyWordListShow = false
      this.brandKeyWord = item.brandName
    },
    addDropBrand2 (item) {
      this.selectObj = item
      this.brandKeyWordListShow2 = false
      this.selectObj.brandName = item.brandName
    },
    selectMyFollowEmit (item) {
      this.selectObj = item
      this.brandKeyWord = item.brandName
      this.isMyFollowShow = false
    },
    selectMyAiEmit (item) {
      this.selectObj = item
      this.brandKeyWord = item.brandName
      this.isMyAiShow = false
    },
    addDropBrandNext (type) {
      if (!this.selectObj.gbid) {
        this.$message({
          type: 'warning',
          message: '计划品牌不能为空！'
        })
        return false
      }
      // if (this.planId === '') {
      //   this.$message({
      //     type: 'warning',
      //     message: '请选择落位方案！'
      //   })
      //   return false
      // }
      if (this.floorStr && this.floorStr.length === 0) {
        this.$message({
          type: 'warning',
          message: '落位铺位不能为空！'
        })
        return false
      }
      const data = {}
      data.berthId = this.storeVal
      data.brandId = this.selectObj.brandId
      data.brandName = this.selectObj.brandName
      data.projectId = this.selectprojectId || this.projectId
      data.gbid = this.selectObj.gbid
      data.planId = this.planId
      if (type === 'edit') {
        data.id = this.editId
        if (this.isDropStatus) {
          data.isFirst = 1
        } else {
          data.isFirst = 0
        }
      } else {
        if (this.isDrop) {
          data.isFirst = 1
        } else {
          data.isFirst = 0
        }
      }
      this.axios.post(api.saveDropBrand, data)
        .then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '添加成功！'
            })
            this.brandKeyWord = ''
            this.selectObj = {}
            this.floorVal = ''
            this.isDrop = true
            this.isAddBrand = false
            if (type === 'edit') {
              this.editIndex = null
            }
            this.flag = true
            this.getShopPlanList(this.storeVal)
            // 刷新地图数据
            this.$emit('addDropBrandEmitSuccess')
            if (this.planData.planBrands && this.planData.planBrands.length === 0) {
              this.isDrop = true
              this.isDisadled = true
            } else {
              this.isDrop = false
              this.isDisadled = false
            }
          } else {
            // this.$message({
            //   type: 'warning',
            //   message: res.data.msg + '！'
            // })
          }
        })
    },
    addDropBtn () {
      if (this.storeVal) {
        if (JSON.stringify(this.planData) !== '{}' && this.planData.planBrands.length < 5000) {
          this.isAddBrand = !this.isAddBrand
          this.addPlanBtn = false
          this.editIndex = null
          if (this.planData.planBrands.length === 0) {
            this.btnText = '智能品牌匹配'
          } else {
            this.btnText = '备选品牌推荐'
          }
        }
      } else {
        this.$message({
          type: 'warning',
          message: '请先选择要落位的铺位！'
        })
      }
    },
    editDropBrand (item, index) {
      this.selectObj = item.brand
      if (JSON.stringify(this.planData) !== '{}' && this.planData.planBrands.length === 1) {
        this.isDisadled2 = true
      } else {
        this.isDisadled2 = false
      }
      if (item.isFirst === 1) {
        this.isDropStatus = true
        this.isDisadled2 = true
      } else {
        this.isDropStatus = false
        this.isDisadled2 = false
      }
      this.editId = item.id
      this.editIndex = index
      this.isAddBrand = false
      this.addPlanBtn = false
    },
    hideAddDrop () {
      if (this.planData.planBrands && this.planData.planBrands.length !== 0) {
        this.isAddBrand = false
        this.addPlanBtn = true
      } else {
        this.isAddBrand = false
        this.addPlanBtn = true
      }
    },
    hideAddDropEdit () {
      this.editIndex = null
      this.addPlanBtn = true
    },
    toBrandLibrary () {
      this.isBrandLibrary = true
      this.brandKeyWordListShow = false
    },
    keepBrandFunc (item) {
      this.selectObj = item
      this.brandKeyWord = item.brandName
      this.isBrandLibrary = false
    },
    selectRecomBrand (item, index) {
      if (index === this.clickIndex) {
        this.clickIndex = -1
        this.selectObj = {}
        this.brandKeyWord = ''
        return false
      } else {
        this.selectObj = item
        this.brandKeyWord = item.brandName
        this.clickIndex = index
      }
    },
    changeActive ($event, index) {
      this.moIndex = index
    },
    remoActive ($event) {
      this.moIndex = ''
    },
    hideAddDropClose () {
      this.$emit('hideAddDropFunc')
    },
    hideMyFollowEmit () {
      this.isMyFollowShow = false
    },
    toMyFollow () {
      this.isMyFollowShow = true
    },
    toMyAi (type, item) {
      if (item) {
        this.brandDataProp = item.brand
      }
      if (this.planData.planBrands && this.planData.planBrands.length === 0) {
        this.matchType = 'add'
      } else {
        this.matchType = 'edit'
        if (this.btnText === '备选品牌推荐') {
          this.brandDataProp = this.planData.planBrands[0].brand
        }
      }
      // this.matchType = type
      if (this.storeVal) {
        this.isMyAiShow = true
      }
    },
    hideAiBrandEmit () {
      this.isMyAiShow = false
    },
    // 删除落位品牌
    deleteDropBrnad (state, id, brandName) {
      if (!this.delset) {
        this.$message({
          message: '暂无权限，如需开通权限，请联系管理员开通'
        })
        return false
      }
      if (state === '待招商' || state === '无意向' || state === '已搁置') {
        // 删除落位不提示
        this.$confirm('确定要删除"' + brandName + '"吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: 'invitePosition'
        }).then((res) => {
          this.axios.post(api.delBerthPlanBrand, { id: id })
            .then((res) => {
              this.getShopPlanList(this.storeVal)
              this.$emit('addDropBrandEmitSuccess')
            })
        })
      } else if (state === '已联系' || state === '已报价' || state === '已预定' || state === '已看场' || state === '待跟进') {
        // 删除落位要提示
        this.$confirm('当前落位品牌正在招商中', '确定要删除"' + brandName + '"吗?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: 'invitePosition'
        }).then((res) => {
          this.axios.post(api.delBerthPlanBrand, { id: id })
            .then((res) => {
              this.getShopPlanList(this.storeVal)
              this.$emit('addDropBrandEmitSuccess')
            })
        })
      } else if (state === '已签约') {
        // 删除签约删除落位要提示
        this.$confirm('当前落位品牌已经签约，删除后将同步删除签约数据', '确定要删除"' + brandName + '"吗?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: 'invitePosition'
        }).then((res) => {
          if (this.brandDetails.planBrand) {
            this.axios.post(api.delBerthPlanBrand, { id: id })
              .then((res) => {
                this.getShopPlanList(this.storeVal)
                this.$emit('addDropBrandEmitSuccess')
              })
          }
        })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.search-list
  position absolute
  top 35px
  left 0px
  width 392px
  max-height 320px
  padding-top 1px
  z-index 11111
  background-color #fff
  overflow-y scroll
  box-shadow 2px 4px 10px #888
  border-radius 5px
  border-top 1px solid #ccc
  li
    height 36px
    line-height 36px
    text-align left
    text-indent 24px
    border-bottom 1px solid #cccccc
    font-size 14px
    cursor pointer
    &:hover
      color #FFA134
.drop-content, .search-list
  &::-webkit-scrollbar
    width 6px
  &::-webkit-scrollbar-track
    // background-color: #f2f2f2;
    border-radius 10px
  /* 滚动条的滑轨背景颜色 */
  &::-webkit-scrollbar-thumb
    background-color #5f5f5f
    border-radius 10px
  /* 滑块颜色 */
  &::-webkit-scrollbar-button
    // background-color: #000;
  /* 滑轨两头的监听按钮颜色 */
  &::-webkit-scrollbar-corner
    // background-color: black;
  /* 横向滚动条和纵向滚动条相交处尖角的颜色 */
.drop-brand
  position fixed
  left 0
  top 0
  bottom 0
  right 0
  background rgba(0, 0, 0, 0.5)
  z-index 1111
  .add-drop
    position absolute
    left 50%
    margin-left -440px
    top 80px
    width 880px
    background #ffffff
    border-radius 6px
    .el-icon-close
      position absolute
      right 20px
      top 15px
      color #D9D9D9
      cursor pointer
    h3
      font-size 18px
      color #333
      font-weight bold
    .drop-content
      height 475px
      overflow-y auto
      padding-left 20px
      padding-right 20px
      padding-bottom 50px
.content-wrap
  height 110px
  .title
    position relative
    font-size 14px
    padding-left 8px
    margin-top 30px
    margin-bottom 30px
    span
      position absolute
      left 0
      top 3px
      width 2px
      height 14px
      background #ffa134
  .search-wrap
    p
      color #333
      font-size 14px
      font-weight 500
      margin-bottom 10px
    .el-select
      width 390px
      .el-input__inner
        width 290px
        height 32px
        line-height 32px
        padding-left 12px
        border-radius 2px
        border 1px solid rgba(236, 236, 236, 1)
    .el-cascader
      width 390px
.content-left
  position relative
  box-sizing border-box
  border 1px solid rgba(255, 161, 52, 0.6)
  height 120px
  margin-bottom 20px
  margin-top 30px
  .add-drop-info
    width 76%
    // height 135px
    .left-item
      font-size 14px
      color #333
      margin-top 25px
      margin-bottom 20px
      font-weight 400
      padding-left 19px
      p
        margin-bottom 10px
        font-size 14px
        color #333
        font-weight 400
      .search-wrap
        position relative
        .selectBtn
          display inline-block
          width 120px
          height 32px
          line-height 32px
          text-align center
          color #FFA134
          font-size 14px
          background rgba(255, 255, 255, 1)
          border 1px solid #FFA134
          font-weight 400
          cursor pointer
          margin-left -20px
          vertical-align bottom
          .icon-intelligence:before
            color #FFA134
            font-size 14px
        .selectBtn2
          display inline-block
          width 120px
          height 32px
          line-height 31px
          text-align center
          color #333333
          font-size 14px
          border 1px solid rgba(217, 217, 217, 1)
          background rgba(255, 255, 255, 1)
          font-weight 400
          cursor pointer
          margin-left 16px
        input
          width 260px
          height 32px
          line-height 32px
          padding-left 12px
          border-radius 2px
          border 1px solid rgba(236, 236, 236, 1)
      .dropBtn
        margin-left 20px
.drop-btn-right
  position absolute
  right 0
  top 0
  width 200px
  height 120px
  border-left 1px solid rgba(255, 161, 52, 0.2)
  .el-icon-close
    position absolute
    right -1px !important
    top -22px !important
    width 37px
    height 22px
    background rgba(198, 179, 164, 1)
    text-align center
    line-height 22px
    &:before
      color #fff
      font-size 12px
  .determine
    margin auto
    width 108px
    height 32px
    line-height 32px
    border-radius 0px 2px 2px 0px
    background #FFA134
    color #fff
    text-align center
    margin-top 24px
    cursor pointer
    border-radius 16px
  .cancal
    margin auto
    width 106px
    height 30px
    line-height 32px
    border-radius 0px 2px 2px 0px
    border 1px solid #FFA134
    color #FFA134
    text-align center
    margin-top 17px
    border-radius 16px
    cursor pointer
.btn-wrap
  text-align center
  margin-top 60px
  span
    display inline-block
    width 80px
    height 40px
    line-height 40px
    text-align center
    color #fff
    background #FFA134
    font-size 16px
    font-weight 400
    border-radius 4px
    cursor pointer
    margin 0 10px
    &.cancal
      background #ffffff
      color #FFA134
      border 1px solid #FFA134
    &.w200
      width 160px
.brand-list
  &::-webkit-scrollbar
    width 6px
  &::-webkit-scrollbar-track
    // background-color: #f2f2f2;
    border-radius 10px
  /* 滚动条的滑轨背景颜色 */
  &::-webkit-scrollbar-thumb
    background-color #D9D9D9
    border-radius 10px
  /* 滑块颜色 */
  &::-webkit-scrollbar-button
    // background-color: #000;
  /* 滑轨两头的监听按钮颜色 */
  &::-webkit-scrollbar-corner
    // background-color: black;
  /* 横向滚动条和纵向滚动条相交处尖角的颜色 */
.Exchange
  position absolute
  bottom 10px
  left 50%
  color #FFA134
  font-size 12px
  font-weight 500
  cursor pointer
  margin-left -18px
div.checked
  color #666
.no-recommend-brand
  text-align center
  color #666
  margin-top 0px
  p
    margin-top 10px
    font-size 12px
  .btn
    width 200px
    height 40px
    color #fff
    background #FFA134
    border-radius 4px
    text-align center
    line-height 40px
    margin auto
    margin-top 20px
    cursor pointer
.star-tag
  position absolute
  left 3px
  top 3px
  width 20px
  height 27px
  background url('~@/assets/images/workBench/star-label.png') no-repeat
  background-size 100%
  z-index 111
.projectName
  width 100%
  background rgba(255, 161, 52, 0.1)
  color #995F1D
  margin-top 24px
  height 40px
  line-height 40px
  font-size 16px
  padding-left 22px
  box-sizing border-box
.drop-brand-wrap
  margin-top 30px
  .title
    position relative
    font-size 14px
    padding-left 8px
    margin-top 20px
    margin-bottom 20px
    span
      position absolute
      left 0
      top 3px
      width 2px
      height 14px
      background #ffa134
.add-drop-btn
  width 100%
  height 43px
  line-height 43px
  color #ffa134
  box-shadow 0px 0px 18px 0px rgba(0, 0, 0, 0.06)
  border-radius 0px 0px 6px 6px
  text-align center
  font-size 14px
  margin-top 0px
  margin-bottom 0px
  cursor pointer
.cannot
  color #999
.plan-brand-wrap
  .plan-brand-item
    display flex
    align-items center
    height 77px
    box-shadow 0px 5px 32px 0px rgba(0, 0, 0, 0.06)
    margin-bottom 20px
    position relative
    .icon-del
      position absolute
      right 10px
      top 3px
      cursor pointer
      &:before
        color #d9d9d9
        font-size 16px
    .icon-edit2
      position absolute
      right 40px
      top 3px
      cursor pointer
      &:before
        color #d9d9d9
        font-size 16px
    img
      width 77px
      height 57px
      margin-left 10px
      margin-right 10px
      object-fit cover
    .info
      cursor pointer
      .brandName
        font-size 14px
        color #333
        font-weight 500
        text-decoration underline
      .formatName
        font-size 12px
        color #999
        font-weight 500
        margin-top 5px
        margin-bottom 7px
        span
          margin-left 30px
          display inline-block
          width 150px
        i
          display inline-block
          width 150px
.fixed-wrap
  height 50px
  line-height 50px
  padding-left 20px
.fixed-shadow
  box-shadow 0px 5px 10px 0px rgba(4, 35, 78, 0.01)
.projectName2
  padding-left 10px
.no-data-tips
  width 100%
  height 32px
  line-height 32px
  background rgba(251, 242, 242, 1)
  border-radius 2px
  color #DF7A4E
  font-size 12px
  padding-left 16px
  box-sizing border-box
.sort-num
  position absolute
  left 2px
  top 2px
  width 18px
  height 18px
  background rgba(255, 161, 52, 0.8)
  color #ffffff
  line-height 18px
  text-align center
</style>
